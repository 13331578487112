
                                @import '~/home/pymedi/app/pymedi/static/scss/includes/_i_mixins';
                                @import '~/home/pymedi/app/pymedi/static/scss/includes/_i_variables';
                            
@include min-screen(1280px) {
   .container {
       max-width: 1240px;
   }
}

a {
    color: #0092DB;
}
a.nounderline:hover {
    text-decoration: none;
}

/* Heartbeat sidebar custom width */
.col-sidebar-wider {
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    max-width: 28%;
}

/* Newsfeeds wrapper */
.news-feeds h4 {
    font-size: 20px;
    letter-spacing: -0.2px;
    font-weight: bold;
}

/* User info CME credits custom link color */
.heart-beat-page .user-info__credits a {
    color: #1ec8d1;
}
/* Webinar title custom link color */
.heart-beat-page .webinar__title a {
    color: #1ec8d1;
}

.webinar__title a {
    color: #222;
}
.webinar__title a:hover {
    color: #0092DB;
    text-decoration: none;
}
.filters .filters__list a.filters__item:hover {
    color: #0092DB;
    text-decoration: none;
}

/* Video page. Info details below video content. Works for PDF item too */
.video-info {
    padding: 13px 0 0 !important;

    @include min-screen(992px) {
        padding: 16px 16px 4px !important;
    }
}

.video-info .video-data-wrap {
    display: flex;
    flex-wrap: wrap;
}

.video-info .video-images {
    display: inline-flex;
    column-gap: 8px;
    align-items: center;
    max-width: calc(28% - 8px);
    height: 32px;
    margin: 0 8px 0 0;

    @include min-screen(992px) {
        align-items: flex-start;
        max-width: calc(30% - 24px);
        height: 48px;
        margin: 0 24px 0 0;
    }
}

.video-info .video-channel-link {
    width: 56px;
    height: auto;
    margin-right: 8px;

    @include min-screen(992px) {
        width: auto;
        margin: 0;
    }
}

.video-info .video-channel-link img {
    display: block;
    width: 100%;
    height: auto;
    height: 32px;
    object-fit: contain;

    @include min-screen(992px) {
        height: 48px;
    }
}

.video-info .video-title-wrap {
    display: inline-flex;
    width: calc(72% - 8px);

    @include min-screen(992px) {
        top: 0;
        display: flex;
        flex-direction: column;
        width: calc(72% - 24px);
        flex-grow: 1;
    }
}

.video-info .video-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #000;
    margin-bottom: 0;

    @include min-screen(992px) {
        font-size: 18px;
        line-height: 21.6px;
        letter-spacing: 0;
    }
}

.video-info .video-subtitle {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    color: #8b8d92;
    letter-spacing: 0.5px;
    margin: 8px 0 0;

    &.show-on-desktop {
        display: none;

        @include min-screen(992px) {
            display: block;
            margin: 8px 0 0;
        }
    }

    &.show-on-mobile {
        display: block;

        @include min-screen(992px) {
            display: none;
        }
    }
}

.video-info .video-subtitle a {
    color: #4f4f4f;
    font-weight: bold;
    text-transform: uppercase;
}

.video-info .badge {
    font-size: 11px;
    color: #4f4f4f;
}

.video-info .profile-image {
    flex: 0 0 auto;
}

.video-meta-info {
    display: flex;
    align-items: center;
    margin: 8px 0 0;

    @include min-screen(992px) {
        justify-content: flex-end;
        border-bottom: 1px solid #E1E1E1;
        margin: 8px 16px 0;
    }
}

.video-meta-info .video-meta-info-divider {
    width: 4px;
    height: 4px;
    margin: 0 8px;
    border-radius: 100%;
    background-color: #636366;

    @include min-screen(992px) {
        display: none;
    }
}

.video-meta-info .video-views,
.video-meta-info .video-date {
    font-size: 14px;
    line-height: 20px;
    color: #636366;
    letter-spacing: 0.005em;
}

.video-meta-info .video-views {
    @include min-screen(992px) {
        display: none;
    }
}

.video-meta-info .video-date {
    @include min-screen(992px) {
        display: flex;
        align-items: center;
        column-gap: 4px;
        font-size: 12px;
        line-height: 18px;
        color: #8b8d92;
    }
}

.video-meta-info .video-date .fa-calendar {
    display: none;

    @include min-screen(992px) {
        display: block;
    }
}

.video-disease-page {
    display: block;

    margin: 0 0 12px;
    padding: 10px;

    border: 1px solid #e1e1e1;
    border-radius: 40px;

    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &,
    &:hover,
    &:active,
    &:focus {
        color: #18191a;
    }

    &:hover,
    &:active {
        border-color: #979fa0;
        text-decoration: none;
    }

    &:active {
        background-color: #f1f3f3;
    }

    @include min-screen($md) {
        display: none;
    }

    .icon {
        display: inline-block;
        line-height: 20px;
        vertical-align: bottom;
    }

    &__name {
        display: inline-block;
        font-weight: 600;

        .icon {
            margin-right: 8px;
            font-size: 20px;
        }
    }

    &__details {
        display: inline-block;
        font-weight: 400;
        color: #717175;

        .icon {
            margin-left: 8px;
            font-size: 16px;
        }
    }
}

.item-views {
    color: #000;
    font-size: 18px;
    line-height: 32px;
}

.item-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0 15px;
    margin: 12px -15px 0;

    @include min-screen(992px) {
        justify-content: flex-start;
        padding: 0;
        margin: 16px 16px 0;
    }
}

.item-actions {
    .thumbs-up-button,
    .share-button-wrap,
    .save-button-wrap
    .follow-button-wrap {
        margin: 0;
    }

    .thumbs-up-button,
    .share-button,
    .save-button {
        display: flex;
        align-items: center;
        column-gap: 4px;
        height: 32px;

        .icon {
            font-size: 16px;
        };
    }
}

.item-actions .item-views-wrap {
    display: none;

    @include min-screen(992px) {
        display: block;
        margin-left: auto;
    }
}

/* Campaign, Featured */
.campaign {
    background: #fffeed;
}
.campaign .related-item {  /* Lesser margin bottom on featured item */
    margin-bottom: .6em;
}
.campaign .video-item {  /* Lesser margin bottom on featured item */
    margin-bottom: 16px;
}

/* Webinar */
.webinar {  /* override width to let webinar breath full width */
    width: 100%;
}
.webinar-page .event-header__info {
    width: 100%;
}
.webinar-page .event-header__img {
    max-width: 150px;
}
.webinar-page .event-header .rsvp-btn {
    margin: 0;
    padding: 0;
    min-width: unset;
}
.webinar-page .event-time {
    font-size: 1.2em;
}
.webinar-page .event-time .event-action {
    margin: 0;
    margin-right: 32px;
}
.webinar-page .event-action {
    /* Slimmer webinar action buttons */
    padding: 0;
}


/* Education Event */
.event-page .event-time {
    font-size: 1.2em;
}
.event-page .event-header__img {
    max-width: 150px;
}
.event-page .event-action {
    /* Slimmer event action buttons */
    padding: 0;
}

.event-timezone {
    font-size: 12px;
    font-weight: normal;
}
.event-timezone a {
    color: #4f4f4f;
    text-transform: uppercase;
}


/* Sponsor image (webinar,...) setup */
.sponsor-image {
    max-width:60%;
    min-width:50px;
    margin-left:auto;
    margin-right:auto;
}

/* Overlay on images */
.img-overlay {
    position: relative;
    display: inline-block;
}
.overlay {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    font-size: 12px;
    background-color: black;
    color: white;
    line-height: 14px;
    opacity: 0.75;
    font-weight: bold;
    padding: 0 4px;
    display: inline-block;
}
a:hover .overlay {
    display: none;
}
a:hover .overlay.video-duration {
    display: inline-block;
}
.overlay.video-duration {
    display: inline-block;
    border-radius: 20px;
    padding: 2px 6px 2px 6px;
}
.info-overlay {
    background-color: #efefef;
    color: #111;
    /*top: 5px;*/
    /*bottom: unset;*/
}

/* Next video in 1.2.3.4 overlay */
.overlay-autoplay {
    z-index: 11001; /* must be larger than brightcove video player */
    position: absolute;
    top: 0px;
    left: 15px;
    right: 15px;
    text-align: center;
    background: #f4f4f4;
    background: rgba(245, 245, 245, 0.7);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 3px 5px;
}
/* Channel item background */
.one-channel {
    background: #f5f5f5;
}
/* Follow button */
.follow-button {
    display:inline;
}
.follow-button a {  /* TODO: Same as video-subtitle a tag. On channel page maybe bigger? */
    color: #4f4f4f;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
}

/* Overrides heartbeat css classes. Widths, margins... */
.heart-beat-page .action {
    width: 100%;
}
.heart-beat-page .webinars__title {
    min-width: 100%;
}

/* Video item (content long) customization */
.video-item {
    padding: 4px;
    margin-bottom: 16px;
    border-bottom: 2px solid #f5f5f5;
}
.video-item .beat-info {
    font-size:12px;
    color: #ccc;
    font-weight: bold;
}
.video-item .beat-info-featured {
    font-weight:bold;
    color:#e9d2b8;
}
.video-item__featured-container {
    /*text-align: center;*/
    width: 300px;
    margin-right: 32px;
    height: 100%; /* for responsive overlay video duration and watch later button */
}
.video-item__img-container {
    max-height: 170px; /* Needed for proper item overlay placement */
    margin-right: 20px;
}
.video-item__img-container-center {  /* For proper Channel, Event image display *(images are not cropped) */
    min-width: 300px;
    min-height: 170px;
    max-height: none;  /* Reset max height from video-item__img-container since images are not cropped but scaled to 300px width, height is arbitrarily. */
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* max-width: 100%;  /* Image on center in mobile view */
}
.video-item__img-container a:hover {
    text-decoration: none;
}
.video-item__img {
    /* width: 100%;  /* for responsive overlay video duration and watch later button */
}
.video-item__img-placeholder {
    min-height: 120px;
    min-width: 120px;
}
/* (heartbeat) item preview holder (webinar, discussion, no thumbnail, ...) */
.item-img-holder {
    width: 200px; /* Same size as loaded thumbnails */
    height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 85%;
    padding-top: 1em;
}
.video-item__info {
    width: 100%;
}
.video-item__author {
    margin-top: 4px;
    margin-bottom: 2px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    width: 100%;
}
.video-item__author a {
    color: #8b8d92;
}
.video-item__title {
    margin-bottom: 4px;
    width: 100%;  /* Block-level child is supposed to occupy the full width of the parent. IE wants explicit statement */
}
.video-item__title a {
    color: #20262b;
}
.video-item__title a:hover {
    color: #0092DB;
    text-decoration: none;
}
.video-item__desc {
    color: #8b8d92;
    width: 100%;
}
.video-item__desc a {
    color: #4f4f4f;
    font-weight: normal;
    text-transform: uppercase;
}
.video-item--product-workshop .video-item__number_of_views {
    display: none;
}

/* Related sidebar */
@include min-screen($md) {
    .related-sidebar {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.related-sidebar {

    &__campaign_videos_wrap {
        body.video-page & {
            margin-bottom: 16px;
        }
    }

    &__banner_wrap {
        @include max-screen($xl - 1) {
            display: none;
        }
    }

    .related-container {
        border: 1px solid #e1e1e1;
        border-radius: 8px;
        padding: 12px;

        body.video-page & {
            border: 1px solid get-color(neutral, 300);
        }

        & + .related-container {
            margin: 16px 0 0;
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: calc(100% - 8px);
            height: 48px;
            border-radius: 12px;
            border: none !important;
            margin: 12px 4px 0;
            background-color: #f2f2f7;
            outline: none !important;
            appearance: none;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 16px;
            letter-spacing: -0.16px;
            color: #000;
            text-decoration: none;
            transition: all 0.3s;

            &:hover {
                background-color: #ededf2;
            }

            &:active {
                background-color: #E5E5EA;
            }

            .icon {
                font-size: 20px;
            }
        }

        &__title {
            font-size: 20px;
            font-weight: 100;
            display: inline;
            margin: 0;

            &--bold {
                font-weight: 600;
            }
        }

        &__tabs_wrap {
            margin: 0 0 16px;

            .video-page & {
                margin: 0;
            }
        }

        &__content_wrap {
            display: none;

            &.is-active {
                display: block;
            }
        }

        &__autoplay_button {
            display: inline-flex;
            align-items: center;
            margin: 16px 0 0;
            font-family: "Lato", sans-serif;
            font-size: 12px;
            font-weight: 700;
        }

        &__heading_wrap {
            body.video-page & {
                padding: 12px;
                margin: -12px -12px 19px;
                background-color: get-color(bg, quaternary);
                border-radius: 8px 8px 0 0;

                .related-heading {
                    h4 {
                        color: #212529;
                    }
                }

                .horizontal_tabs {
                    margin: 12px 0 0;

                    &__item:not(.is-active) {
                        background-color: #fff;
                        color: #000;
                    }
                }
            }
        }

        &__group {
            & + .related-container__group {
                margin: 24px 0 0;
            }

            &:not(.is-expanded) .related-item,
            &:not(.is-expanded) .related_video_card {
                &:nth-child(n+3) {
                    display: none;
                }
            }
        }

        &__group_title {
            display: flex;
            align-items: center;
            column-gap: 8px;
            margin: 0 0 15px;
            font-family: $fontPrimary;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #212529;

            .icon {
                font-size: 24px;

                &.icon-diagnostics-and-biomarkers-bold::before {
                    content: "\e927";
                }
            }
        }

        &__group_button {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 8px 7px;
            border-radius: 12px;
            background-color: get-color(button, background, secondary, default);
            margin: 16px auto 0;
            font-family: $fontPrimary;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.07px;
            color: get-color(button, text, secondary, default);
            appearance: none;
            border: none;
            outline: none;
            cursor: pointer;
            transition: all 0.25s;

            &:hover {
                background-color: get-color(button, background, secondary, hover);
            }

            .icon {
                font-size: 16px;
            }
        }

    }

    .related-heading {
        display: flex;
        align-items: center;

        h5 {
            margin: 8px 0 0;
            font-family: "Open Sans";
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            color: #000;
        }
    }
}

#toggle-autoplay {
    padding: 4px;
    width: 38px;
}

.channel-tab-title {
    font-size: 20px;
    font-weight: bold;
}
.item-tile {
    padding: 0px;
}
.item-tile .video-item {
    flex-direction: column;
    margin-bottom: 30px;
}
.item-tile .video-item__img-container {
    margin-right: 0px;
    margin-bottom: 10px;
}
.item-tile .video-item__img-container a {
    display: block;
}
.item-tile .video-item__info {
    max-width: 300px;
    padding: 0px 8px 0px 8px;
}
.item-tile .video-item__title {
    margin-top: 4px;
}
.item-tile .video-item__desc .mt-3 {
    margin-top: 0.5rem!important;
}

.heartbeat-small .related-item {
    border-bottom: 2px solid #f5f5f5;
}

.heartbeat-small-first .related-item {
    border-top: 2px solid #f5f5f5;
}

/* Related item */
.related-item {
    display: flex;
    width: 100%;
    padding: 4px;
    margin-bottom: 5px;
}
.related-item__title {
    margin-bottom: 2px;
}
.related-item__title a {
    color: #222;
    font-size: 0.8em;
    line-height: 1.1;
}
.related-item__title a:hover {
    color: #0092DB;
    text-decoration: none;
}
.related-item__img {
    max-width: 150px;
}
.related-item__img-container {
    position: relative;
    margin-right: 10px;
    width: 150px;
    max-height: 105px;  /* Needed for proper item overlay placement */
    min-width: 150px;
    text-align: center;
}
.related-item__img-container a {
    display: block;
}
.related-item-img-holder {
    width: 100px; /* Same size as loaded thumbnails */
    height: 65px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 85%;
    padding-top: .4em;
}
.related-item__info {
    width: 100%;
}
.related-item__author {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0.17px;
    text-align: left;
    color: #8B8D92;
    text-transform: uppercase;
}
.related-item__author a {
    color: #8B8D92;
}
.related-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.1px;
    text-align: left;
    color: #20262b;
}
.related-item__desc {
    font-family: "Open Sans", sans-serif;
    /*font-size: .9em;*/
    font-size: 12px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #a4a4a4;
}
.related-item__desc a {
    color: #a4a4a4;
}

.hide {
  display: none;
}

/* actions */
.actions-dropdown {
    min-width: 90px;
}
.actions-dropdown>li {
    padding: 3px 15px;
}
.actions-dropdown>li a {
    padding: 0;
}
.actions-dropdown>li iframe {
    margin-top: 4px;
}
.actions-dropdown>li a:hover {
    color: #999 !important;
    background:#fff !important;
}
.actions-share-email {
    display: inline-block;
    border-radius: 3px;
    border:1px solid #ccc;
    padding:0 5px;
    background: #f3f3f3;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
}

/* filters */
.channel-page .filter-list .filter-item span {
    position: absolute;
    right: 0;
    top: 20px;
}
.channel-list-item {
    min-height: 120px;
}
.channel-list-item .video-item__img-container-center {
    min-height: auto;
}
#channel-tabs .dropdown-menu {  /* put channel tabs dropdown it on top of video player */
    z-index: 19999;
}

.debug {
    background: beige;
    font-size: 80%;
}
.debug pre {
    margin-bottom: 0;
}

/* XXX: Check if obsolete after upgrade of django-bootstrap4 */
.form-group.has-danger input {
    border-color: #dc3545;
}
.form-group.has-danger input:focus {
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}
.form-group.has-danger textarea {
    border-color: #dc3545;
}
.form-group.has-danger textarea:focus {
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}
.form-group.has-danger input[type="radio"]:focus {
    box-shadow: none;
}
.form-group.has-danger select {
    border-color: #dc3545;
}
.form-group.has-danger select:focus {
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}
.form-group.has-danger .select2 input:focus {
    box-shadow: none;
}
.form-group.has-danger .text-muted {
    color: #dc3545 !important;
}
.selected-specialty {
    background-color: transparent;
}
/* Bootstrap aware button classes */
/*  :)  */
/* Use it instead of the theme provided one */
.btn-grad {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: .1em;
    color: #fff;
    background-image: linear-gradient(235deg, #08aeea, #01D4B9);
}
.btn-grad-follow {
    font-size: .875rem; /* Default bootstrap button size */
    text-transform: none;
}

.btn-grad:hover {
    color: #fff;
    background-image: linear-gradient(200deg, #0682af, #01D4B9);
    cursor: pointer;
}
.btn-grad:not([disabled]):not(.disabled).active, .btn-grad:not([disabled]):not(.disabled):active {
    color: #fff;
    background-image: linear-gradient(200deg, #08aeea, #01D4B9);
}
.btn-rounded {
    border-radius: 100px;
}

.btn-green-outline {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: .1em;
    background-color: transparent;
    color: #1ec8d1;
    border-color: #1ec8d1;
}

.btn-green-outline:hover {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: .1em;
    background-image: linear-gradient(216deg, #08aeea, #01D4B9);
    color: #fff;
    border-color: #fff;
}

.btn-green-outline:not([disabled]):not(.disabled).active, .btn-green-outline:not([disabled]):not(.disabled):active {
    color: #fff;
    border-color: #fff;
    background-image: linear-gradient(216deg, #08aeea, #01D4B9);
}

.btn-outline {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: .1em;
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}
.btn-outline:hover {
    color: #fff;
    border-color: #fff;
    /* Default (shiny) background-image: linear-gradient(216deg, #08aeea, #2af598); */
    background-image: linear-gradient(216deg, #08aeea, #01D4B9);
}
.btn-outline:not([disabled]):not(.disabled).active, .btn-outline:not([disabled]):not(.disabled):active {
    color: #fff;
    border-color: #fff;
    background-image: linear-gradient(200deg, #08aeea, #01D4B9);
}
/*Overrides btn-outline-secondary border color*/
.btn-outline-secondary {
    border-color: rgba(0, 0, 0, 0.125);
}
.btn-team-info {  /* Custom button text and border color for team page */
    color: #8e90a0;
    border-color: #c8c8c8;
}

.btn-wide {
    padding-left: 2em;
    padding-right: 2em;
}
.btn-wider {
    padding-left: 4em;
    padding-right: 4em;
}
.btn-taller {
    padding-top: 1em;
    padding-bottom: 1em;
}
.btn-search {
    color: #fff;
    background-image: linear-gradient(235deg, #08aeea, #01D4B9);
}
.btn-search:hover {
    color: #fff;
    background-image: linear-gradient(200deg, #08aeea, #01D4B9);
}
.btn-search:not([disabled]):not(.disabled).active, .btn-search:not([disabled]):not(.disabled):active {
    color: #fff;
    background-image: linear-gradient(200deg, #08aeea, #01D4B9);
}
.btn-video-contact {
    border: none;
    border-radius: 0;
    box-shadow: 0;
    font-size: 12px;
    font-weight: bold;
}
@include screen($sm, $xl - 1) {
    .btn-video-contact {
        width: calc(100% - 2px);
        margin: 0 1px;
    }
}

.btn-video-contact:focus, .btn-video:active:focus, .btn-video-contact.active:focus {
    outline: 0 none;
    box-shadow: none;
}

.btn-comment-action {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-size: .75em;
    letter-spacing: .1em;
    border: solid 1px;
}

.comments {
    margin: 12px 0 35px; // mb should be 40, but we added 5px pb on .unstyled.list-unstyled
    padding-bottom: 5px;

    @include min-screen($md) {
        margin-top: 48px;
    }

    &__title {
        display: flex;
        align-items: center;
        margin: 0 0 16px;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.16px;
        color: #18191a;
    }

    &__title_divider {
        width: 2px;
        height: 2px;
        border-radius: 100%;
        margin: 0 5px;
        background-color: #18191A;
    }

    .media-body a {
        word-break: break-all;
    }

    &__wrapper {
        display: none;
        margin: 16px 0 0;
        transition: all 0.5s;
        overflow: hidden;

        &:has(.media) {
            display: block;
        }

        &.is-collapsed {
            height: 0 !important;
        }

        & > .mt-2 {
            margin-top: 0 !important;
        }

        .unstyled.list-unstyled {
            margin-bottom: 0;
            padding-bottom: 5px; // adding bottom padding because the autoresize function sets the wrong value on mobile

            .pt-2 {
                padding-top: 0 !important;
            }

            .media {
                display: flex;
                column-gap: 12px;

                &:not(:first-child) {
                    margin-top: 16px;
                }

                .mr-2 {
                    margin-right: 0 !important;
                }

                .media-body {

                    // this is the container for the buttons - reply/message/thumbs up
                    .row.mb-4.mt-0 {
                        display: flex;
                        gap: 4px;
                        margin: 8px 0 0 !important;

                        .col-auto {
                            padding: 0 !important;

                            .btn-wide {
                                padding-left: 12px !important;
                                padding-right: 12px !important;
                            }
                        }
                    }
                }

                & + .list-unstyled {
                    margin-bottom: 0;

                    // add margin top to this element only if there are any replies inside it
                    &:has(.media) {
                        margin-top: 16px;
                    }

                    li {
                        margin-left: 52px !important;
                    }
                }

            }
        }
    }

    &__form_wrap {
        .media {
            display: flex;
            column-gap: 12px;
            margin-top: 0 !important;
        }

        .media-body {
            .form-group {
                margin-bottom: 0;

                // element with the options for adding a video/image
                & ~ div[id*="show-rest"] {
                    margin: 16px 0 0;
                }

                textarea.form-control {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.08px;
                }
            }
        }
    }
}

.sort-dropdown {
    font-size: 1.25em;
    font-weight: 300;
    background-color: #fff;
    color: #212529;
}

.row.bootstrap4-multi-input {
    margin: 0px;
}

.modal-footer .nav-link {
    font-family: "Lato", sans-serif;
    font-size: 11px;
    color: #a5a7a9;
    text-transform: uppercase;
    padding: .5rem .5rem;
}
.modal-footer .nav-link {
    border-bottom: 2px solid transparent;
}
.modal-footer .nav-link:hover {
    border-bottom: 2px solid #00B0EC;
}

.physician-match-ul li {
    cursor: pointer;
}


/* Hacks. Refactoring needed */
.cme-quiz ul {
    list-style: none;
}

/* Playlist related list (scrollable content) */
.playlist-related-list {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: none;
}
.playlist-related-list .related-item {  /* Less space between items */
    margin-bottom: .6em;
}

/* PY-13 Do not blur video when paused */
#video-tab .oo-player-container .oo-player-skin-plugins.oo-overlay-blur:not(:empty) {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    filter: blur(0px);
    z-index: -10000;
    position: absolute;
}

#video-tab .oo-player-container video.oo-blur {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    filter: blur(0px);
}

#video-tab .oo-player-container .oo-state-screen .oo-fading-underlay, .oo-player-container .oo-spinner-screen .oo-fading-underlay {
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
}

.calendar-container {
    overflow-y: scroll;
}
.calendar-container > div {
    min-width: 1000px;
}

.pagination .previous {
    margin-right: 5px;
}

.pagination .next {
    margin-left: 5px;
}

.ie-block-level-full-width {
    width: 100%;  /* Block-level child is supposed to occupy the full width of the parent. IE wants explicit statement */
}

.contactme-event-form-group {
    margin-bottom: 15px;
}
.contactme-event-form-group > label {
    margin: 0 5px 0 15px;
}
.contactme-event-form-group > input[type="radio"] {
    transform: translateY(-15%);
}
.contactme-event-form-group > input[type="email"],
.contactme-event-form-group > input[type="text"] {
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
}

.term-child a {
    color: inherit;
    max-width: 80%;
}

.term-child .expand-terms {
    align-self: baseline;
    margin-left: auto;
}

.term-child .expand-terms i {
    cursor: pointer;
    outline: none;
    padding: 3px;
}

/* Semantic css rules */
.center-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    /* old IE's */
    text-align: center;
}

.center-content-vertically {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.left-gutter {
    margin-left: 15px;
}

.right-gutter {
    margin-right: 15px;
}

.side-gutter {
    margin-left: 15px;
    margin-right: 15px;
}

.hidden {
    display: none;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-wrap {
    flex-wrap: wrap;
}

/* Narrow container */
.narrow-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@include min-screen($xl) {
    .narrow-container {
        max-width: 740px;
    }
}
/* /Narrow container */

/* Mobile custom css */
/* up to iPhone SE */
@include max-screen(320px) {
    .pagination .page-link {
        padding: .2rem .4rem;
    }
}

/* up to iPhone 6/7/8 Plus and iPhone X */
@include screen(321px, 414px) {
    .pagination .page-link {
        padding: .25rem .5rem;
    }
}

.react-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: rgba(239, 239, 239, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-overlay.fixed {
    position: fixed;
}
.progress-indicator {
   color: #03c1d2;
}
.fade-item {
    opacity: 0.4;
}

.privacy-policy-notification {
    bottom: 0;
    position: fixed;
    width: 100%;
    text-align: center;
}
.privacy-policy-notification .alert {
    background-color: #09465B;
    color: #fff;
    border-color: #09465B;
    border-radius: 0;
    margin-bottom: 0;
}

.brand-alert-container {
        max-height: 768px;
        width: 400px;
        position: fixed;
        bottom: 0px;
        z-index: 2;
        background: white;
}
.headline-container {
        overflow-y: auto;
        max-height: 256px;
        border-bottom: 2px;
        background: pink;
        display: none;
}

.expanded-container {
        overflow-y: auto;
        max-height: 256px;
        border-bottom: 2px;
        background: red;
        display: none;
}
.isi-container {
        overflow-y: auto;
        max-height: 256px;
        background: blue;
        display: none;
}

/* manufacturer channel page */
.mfc-header {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #F7F7F7;
}

.mfc-navigation {
    border-top: 1px solid #E5E5EA;
    border-bottom: 1px solid #E5E5EA;
}
